import React, { useContext, useState } from "react";
import "./Firstform.css";

import MultiStepFormContext from "./MultiStepFormContext";

const NewComponent = ({ onChange }) => {
  const { formData, setFormData, errorMessage, updateErrorMessage } =
    useContext(MultiStepFormContext);
  const [nextClicked, setNextClicked] = useState(false);

  const { currentStep, setCurrentStep } = useContext(MultiStepFormContext);
  const [fieldValidations, setFieldValidations] = useState({
    software: true,
    oms_manager_name: true,
  });

  const handleNext = () => {
    let isValid = true;
    const updatedValidations = { ...fieldValidations };

    Object.keys(updatedValidations).forEach((fieldName) => {
      const fieldValue = formData[fieldName];

      if (!fieldValue) {
        isValid = false;
        updatedValidations[fieldName] = false;
      } else {
        updatedValidations[fieldName] = true;
      }
    });

    setFieldValidations(updatedValidations);

    if (!isValid) {
      setNextClicked(true);
      updateErrorMessage("Please Enter required fields data");
    } else {
      setCurrentStep(currentStep + 1);
      setNextClicked(false);
      updateErrorMessage("");
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    onChange({ [name]: value });
  };
  return (
    <div className="FirstFormDiv">
      <h1  className="titlefirst">Office Management Software</h1>

      <div className="FieldDiv2">
        <div>
          Please enter the Office Management (OMS) or Electronic Medical Record
          (EMR) software used in your clinics, along with any associated
          contacts for the software. This information is important in setting up
          integrations for your ReviewMe, .Shop services (if applicable).
        </div>
        <input
          type="text"
          placeholder="Software"
          required
          className={fieldValidations.software ? "clrinput" : "error"}
          value={formData?.software}
          onChange={handleInputChange}
          name="software"
        />

        <input
          type="text"
          placeholder="Name of your practice's OMS manager"
          required
          className={fieldValidations.oms_manager_name ? "clrinput" : "error"}
          value={formData?.oms_manager_name}
          onChange={handleInputChange}
          name="oms_manager_name"
        />
      </div>

      <div className="btns">
        <button className="prev" onClick={handlePrev}>
          Prev
        </button>
        <button className="next" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default NewComponent;
