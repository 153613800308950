import React, { useState } from "react";
import "./Terms.css";

const Privacy = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="termdiv">
      {/* <h1>Terms and Conditions</h1> */}
      <div className="terminnerDiv">
        {isLoading && (
          <div className="loader">

          </div>
        )}
        <iframe
          title="Privacy PDF"
          src="https://app.termly.io/embed/terms-of-use/bd58b3f8-39d5-4989-ae86-60d573e30814"
          width="100%"
          height="1024px"
          style={{ border: "none" }}
          onLoad={handleLoad}
        ></iframe>
      </div>
    </div>
  );
};

export default Privacy;
